import "./MainPage.css";
import Navigation from "../UI/Navigation/Navigation";
import Gallery from "../UI/Gallery/Gallery";
import GalleryImage from "../UI/Gallery/GalleryImage/GalleryImage";

const MainPage = () => {
  return (
    <>
      <Navigation />
      <main>
        <section className="hero-section">
          <img
            src={process.env.PUBLIC_URL + "/img/hero.svg"}
            className="hero-img"
            alt="Дом в окружении гор и деревьев"
          />
          <div className="hero-info">
            <img
              className="hero-text diff-text-desktop"
              src={process.env.PUBLIC_URL + "/img/hero_text_desktop.svg"}
              alt="Дом в лесу"
            />
            <img
              className="hero-text diff-text-mobile"
              src={process.env.PUBLIC_URL + "/img/hero_text_mobile.svg"}
              alt="Дом в лесу"
            />
          </div>
        </section>

        <a className="anchor" id="description"></a>

        <section>
          <div className="section-header">
            <img
              src={process.env.PUBLIC_URL + "/img/header_description.svg"}
              alt="Описание"
              className="section-header-img"
            />
            <h2 className="section-header-text">Описание</h2>
          </div>
          <p className="T2">
            Уединитесь в самом сердце природы в потрясающем доме, расположенном
            в лесу, всего в нескольких шагах от входа в национальный парк.
            Погрузитесь в спокойную обстановку и подышите свежим горным воздухом
            в этом идиллическом месте.
            <br />
            <br />
            Благодаря своему очаровательному внешнему виду, этот дом предлагает
            идеальное убежище от оживленной городской жизни. Просыпайтесь каждое
            утро с захватывающим видом на пышный зеленый лес и наслаждайтесь
            завтраком на солнечной террасе, окруженной пением птиц и шелестом
            деревьев.
            <br />
            <br />
            Полностью оборудованные кухня и гриль-зона оснащены современной
            бытовой техникой, позволяющей готовить вкусные блюда из свежих
            местных продуктов.
            <br />
            <br />
            Спальни с захватывающим видом на лес спроектированы в стиле
            минимализма и с учетом комфорта.
            <br />
            <br />
            В ванной комнате установлена небольшая ванна, идеально подходящая
            для релакса после целого дня прогулок по парку.
            <br />
            <br />
            Бесчисленные тропы, которые можно исследовать всего в нескольких
            минутах ходьбы от вашего порога, идеально подходят для любителей
            активного отдыха. Прогуляйтесь по лесу, понаблюдайте за дикой
            природой или устройте пикник на берегу реки. Это поистине рай для
            любителей природы!
            <br />
            <br />
            Воспользуйтесь этой возможностью, которая выпадает раз в жизни, и
            станьте владельцем своего собственного райского уголка. Не
            стесняйтесь, закажите просмотр сегодня и начните свою новую жизнь в
            лесу!
          </p>
        </section>

        <a className="anchor" id="plan"></a>

        <section>
          <div className="section-header">
            <img
              src={process.env.PUBLIC_URL + "/img/header_plan.svg"}
              alt="План дома"
              className="section-header-img"
            />
            <h2 className="section-header-text">План дома</h2>
          </div>
          <div className="plan-subheader">
            <h2 className="plan-subheader-header">1-й этаж</h2>
            <p className="plan-subheader-description">
              Прихожая, гостиная, спальня, санузел, выходы на террасы
            </p>
          </div>
          <img
            src={process.env.PUBLIC_URL + "/img/plan_1_floor.png"}
            alt="План первого этажа"
            className="floor1-img"
          />
          <div className="plan-subheader">
            <h2 className="plan-subheader-header">2-й этаж</h2>
            <p className="plan-subheader-description">
              Спальня, кабинет, гардеробная, ванная
            </p>
          </div>
          <img
            src={process.env.PUBLIC_URL + "/img/plan_2_floor.png"}
            alt="План второго этажа"
            className="floor2-img"
          />
        </section>

        <a className="anchor" id="gallery"></a>

        <section>
          <div className="section-header">
            <img
              src={process.env.PUBLIC_URL + "/img/header_gallery.svg"}
              alt="Фотогалерея"
              className="section-header-img"
            />
            <h2 className="section-header-text">Фотогалерея</h2>
          </div>

          <div className="gallery-section-container">
            <div className="gallery-subsection">
              <h2 className="gallery-subsection-header">Внешний вид</h2>
              <Gallery>
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_1_1.jpg"}
                  desc="Парковка на 2 машины"
                  imgIndex={0}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_1_2.jpg"}
                  desc="Вид с дороги"
                  imgIndex={1}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_1_3.jpg"}
                  desc="Освещенная лестница в дом"
                  imgIndex={2}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_1_4.jpg"}
                  desc="Дорога внутри поселка"
                  imgIndex={3}
                />
              </Gallery>
            </div>

            <div className="gallery-subsection">
              <h2 className="gallery-subsection-header">Прихожая</h2>
              <Gallery>
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_2_1.jpg"}
                  desc="Входная дверь"
                  imgIndex={4}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_2_2.jpg"}
                  desc="Прихожая"
                  imgIndex={5}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_2_3.jpg"}
                  desc="Вход в гостиную"
                  imgIndex={6}
                />
              </Gallery>
            </div>

            <div className="gallery-subsection">
              <h2 className="gallery-subsection-header">Гостиная</h2>
              <Gallery>
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_3_1.jpg"}
                  desc="Лестница на второй этаж и гостиная"
                  imgIndex={7}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_3_2.jpg"}
                  desc="Гостиная"
                  imgIndex={8}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_3_3.jpg"}
                  desc="Удобный диван и большой телевизор"
                  imgIndex={9}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_3_4.jpg"}
                  desc="Вид на кухню-столовую"
                  imgIndex={10}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_3_5.jpg"}
                  desc="Эко-камин"
                  imgIndex={11}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_3_6.jpg"}
                  desc="Полки для книг и сувениров"
                  imgIndex={12}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_3_7.jpg"}
                  desc="Много воздуха за счет открытого пространства"
                  imgIndex={13}
                />
              </Gallery>
            </div>

            <div className="gallery-subsection">
              <h2 className="gallery-subsection-header">Кухня-столовая</h2>
              <Gallery>
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_4_1.jpg"}
                  desc="Стол с розетками для подзарядки"
                  imgIndex={14}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_4_2.jpg"}
                  desc="Удобные винтажные стулья"
                  imgIndex={15}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_4_3.jpg"}
                  desc="Светлая кухня"
                  imgIndex={16}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_4_4.jpg"}
                  desc="Встроенная бытовая техника BOSCH"
                  imgIndex={17}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_4_5.jpg"}
                  desc="Профессиональная кофемашина, посудомоечная машина и измельчитель пищевых отходов"
                  imgIndex={18}
                />
              </Gallery>
            </div>

            <div className="gallery-subsection">
              <h2 className="gallery-subsection-header">Передняя терраса</h2>
              <Gallery>
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_5_1.jpg"}
                  desc="Терраса для утреннего каппучино"
                  imgIndex={19}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_5_2.jpg"}
                  desc="Вид на Национальный парк"
                  imgIndex={20}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_5_3.jpg"}
                  desc="В тени деревьев"
                  imgIndex={21}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_5_4.jpg"}
                  desc="Панорамные окна на 2 этажа"
                  imgIndex={22}
                />
              </Gallery>
            </div>

            <div className="gallery-subsection">
              <h2 className="gallery-subsection-header">
                Спальня на первом этаже
              </h2>
              <Gallery>
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_6_1.jpg"}
                  desc="Тихая спальня на первом этаже"
                  imgIndex={23}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_6_2.jpg"}
                  desc="Кровать с отличным удобным матрасом"
                  imgIndex={24}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_6_3.jpg"}
                  desc="Комод для хранения вещей"
                  imgIndex={25}
                />
              </Gallery>
            </div>

            <div className="gallery-subsection">
              <h2 className="gallery-subsection-header">Гостевой санузел</h2>
              <Gallery>
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_7_1.jpg"}
                  desc="Гостевой туалет и душевая зона"
                  imgIndex={26}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_7_2.jpg"}
                  desc="Раковина из натурального камня ручной работы"
                  imgIndex={27}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_7_3.jpg"}
                  desc="Встроенные смесители"
                  imgIndex={28}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_7_4.jpg"}
                  desc="Стеклянная перегородка душевой зоны"
                  imgIndex={29}
                />
              </Gallery>
            </div>

            <div className="gallery-subsection">
              <h2 className="gallery-subsection-header">
                Лестница на второй этаж
              </h2>
              <Gallery>
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_8_1.jpg"}
                  desc="Винный шкаф, ледогенератор, лестница и выход на заднюю террасу"
                  imgIndex={30}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_8_2.jpg"}
                  desc="Гриль-зона и беседка на второй террасе"
                  imgIndex={31}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_8_3.jpg"}
                  desc="Удобная лестница на второй этаж"
                  imgIndex={32}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_8_4.jpg"}
                  desc="Светлая лестничная зона и вход в ванную комнату"
                  imgIndex={33}
                />
              </Gallery>
            </div>

            <div className="gallery-subsection">
              <h2 className="gallery-subsection-header">Задняя терраса</h2>
              <Gallery>
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_9_1.jpg"}
                  desc="Теневая задняя терраса"
                  imgIndex={34}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_9_2.jpg"}
                  desc="Газовый гриль NAPOLEON"
                  imgIndex={35}
                />
              </Gallery>
            </div>

            <div className="gallery-subsection">
              <h2 className="gallery-subsection-header">
                Кабинет и гардеробная
              </h2>
              <Gallery>
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_10_1.jpg"}
                  desc="Полки для книг и вход в спальню"
                  imgIndex={36}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_10_2.jpg"}
                  desc="Диван в кабинете и большой простор"
                  imgIndex={37}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_10_3.jpg"}
                  desc="Кабинет и вход в гардеробную"
                  imgIndex={38}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_10_4.jpg"}
                  desc="Оптоволоконный интернет 500 мБит"
                  imgIndex={39}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_10_5.jpg"}
                  desc="Зона отдыха"
                  imgIndex={40}
                />
              </Gallery>
              <Gallery>
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_10_6.jpg"}
                  desc="Вид на национальный парк из кабинета"
                  imgIndex={41}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_10_7.jpg"}
                  desc="Гардеробная"
                  imgIndex={42}
                />
              </Gallery>
            </div>

            <div className="gallery-subsection">
              <h2 className="gallery-subsection-header">Второй свет</h2>
              <Gallery>
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_11_1.jpg"}
                  desc="Второй свет, вид на первый этаж"
                  imgIndex={43}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_11_2.jpg"}
                  desc="Много света, очень много света"
                  imgIndex={44}
                />
              </Gallery>
            </div>

            <div className="gallery-subsection">
              <h2 className="gallery-subsection-header">Ванная комната</h2>
              <Gallery>
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_12_1.jpg"}
                  desc="Ванная комната на втором этаже"
                  imgIndex={45}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_12_2.jpg"}
                  desc="Красивая ванна для отдыха"
                  imgIndex={46}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_12_3.jpg"}
                  desc="Самая бесшумная стиральная машина"
                  imgIndex={47}
                />
              </Gallery>
            </div>

            <div className="gallery-subsection">
              <h2 className="gallery-subsection-header">
                Спальня на втором этаже
              </h2>
              <Gallery>
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_13_1.jpg"}
                  desc="Детская спальня на втором этаже"
                  imgIndex={48}
                />
                <GalleryImage
                  src={process.env.PUBLIC_URL + "/img/gallery_13_2.jpg"}
                  desc="Телевизор с мультиками"
                  imgIndex={49}
                />
              </Gallery>
            </div>
          </div>
        </section>

        <a className="anchor" id="video"></a>

        <section id="video">
          <div className="section-header">
            <img
              src={process.env.PUBLIC_URL + "/img/header_video.svg"}
              alt="Видео-презентация"
              className="section-header-img"
            />
            <h2 className="section-header-text">Видео-презентация</h2>
          </div>
          <iframe
            src="https://www.youtube.com/embed/Bp4MKvsd82s"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </section>

        <a className="anchor" id="location"></a>

        <section>
          <div className="section-header">
            <img
              src={process.env.PUBLIC_URL + "/img/header_location.svg"}
              alt="Местоположение"
              className="section-header-img"
            />
            <h2 className="section-header-text">Местоположение</h2>
          </div>

          <h4 className="T2 text-center">
            Легкая доступность к основным центрам притяжения Сочи
          </h4>

          <div className="grid-4">
            <div className="grid-4-item">
              <img
                src={process.env.PUBLIC_URL + "/img/location_1.jpg"}
                alt="Дом в лесу - Морской вокзал Сочи"
                className="grid-4-img"
              />
              <h4 className="T2">Дом в лесу - Морской вокзал Сочи</h4>
              <h3 className="T1">35 минут</h3>
            </div>

            <div className="grid-4-item">
              <img
                src={process.env.PUBLIC_URL + "/img/location_2.jpg"}
                alt="Дом в лесу - Аэропорт Сочи"
                className="grid-4-img"
              />
              <h4 className="T2">Дом в лесу - Аэропорт Сочи</h4>
              <h3 className="T1">43 минуты</h3>
            </div>

            <div className="grid-4-item">
              <img
                src={process.env.PUBLIC_URL + "/img/location_3.jpg"}
                alt="Дом в лесу - Красная поляна"
                className="grid-4-img"
              />
              <h4 className="T2">Дом в лесу - Красная поляна</h4>
              <h3 className="T1">1 час 10 минут</h3>
            </div>

            <div className="grid-4-item">
              <img
                src={process.env.PUBLIC_URL + "/img/location_4.jpg"}
                alt="Дом в лесу - Хоста"
                className="grid-4-img"
              />
              <h4 className="T2">Дом в лесу - Хоста</h4>
              <h3 className="T1">20 минут</h3>
            </div>
          </div>
        </section>

        <a className="anchor" id="sights"></a>

        <section id="sights">
          <div className="section-header">
            <img
              src={process.env.PUBLIC_URL + "/img/header_sights.svg"}
              alt="Достопримечательности поблизости"
              className="section-header-img"
            />
            <h2 className="section-header-text diff-text-desktop">
              Достопримечательности поблизости
            </h2>
            <h2 className="section-header-text diff-text-mobile">
              Классные места поблизости
            </h2>
          </div>

          <h4 className="T2 text-center">
            Фантастические места практически в шаговой доступности
          </h4>

          <div className="grid-4">
            <div className="grid-4-item">
              <img
                src={process.env.PUBLIC_URL + "/img/sights_1.jpg"}
                alt="Хостинская тисосамшитовая роща"
                className="grid-4-img"
              />
              <h4 className="T2">Хостинская тисосамшитовая роща</h4>
              <h3 className="T1">10 минут</h3>
            </div>

            <div className="grid-4-item">
              <img
                src={process.env.PUBLIC_URL + "/img/sights_2.jpg"}
                alt="Хостинские чайные плантации"
                className="grid-4-img"
              />
              <h4 className="T2">Хостинские чайные плантации</h4>
              <h3 className="T1">7 минут</h3>
            </div>

            <div className="grid-4-item">
              <img
                src={process.env.PUBLIC_URL + "/img/sights_3.jpg"}
                alt="Воронцовские пещеры"
                className="grid-4-img"
              />
              <h4 className="T2">Воронцовские пещеры</h4>
              <h3 className="T1">15 минут</h3>
            </div>

            <div className="grid-4-item">
              <img
                src={process.env.PUBLIC_URL + "/img/sights_4.jpg"}
                alt="Навалищенское ущелье"
                className="grid-4-img"
              />
              <h4 className="T2">Навалищенское ущелье</h4>
              <h3 className="T1">10 минут</h3>
            </div>
          </div>
        </section>

        <section>
          <div className="section-header">
            <img
              src={process.env.PUBLIC_URL + "/img/header_contacts.svg"}
              alt="Контакты"
              className="section-header-img"
            />
            <h2 className="section-header-text">Контакты</h2>
          </div>
          <div className="socials">
            <a href="https://t.me/+79112939998">
              <div className="social">
                <div className="telegram"></div>
                <p className="T1 text-center">Telegram</p>
              </div>
            </a>
            <a href="https://wa.me/+79112939998">
              <div className="social">
                <div className="whatsapp"></div>
                <p className="T1 text-center">WhatsApp</p>
              </div>
            </a>
            <a href="mailto:domvlesusochi@gmail.com">
              <div className="social">
                <div className="email"></div>
                <p className="T1 text-center">Email</p>
              </div>
            </a>
          </div>
          <a id="contacts" href="tel:+7 911 293 9998">
            <div className="phone">
              <div className="phone-img"></div>
              <p className="H2">+7 911 293 9998</p>
            </div>
          </a>
        </section>
      </main>
    </>
  );
};

export default MainPage;
