import "./GalleryImage.css";
import { useState } from "react";
import GalleryModal from "../GalleryModal/GalleryModal";

const GalleryImage = (props) => {
  const [isModalShown, setIsModalShown] = useState(false);

  const closeModalHandler = () => {
    setIsModalShown(false);
  };

  const showModalHandler = () => {
    setIsModalShown(true);
  };

  return (
    <>
      <div className="gallery-image-container" onClick={showModalHandler}>
        <img src={props.src} alt={props.desc} className="gallery-img" />
        <h4 className="T2 text-center">{props.desc}</h4>
      </div>
      {isModalShown && (
        <GalleryModal
          imgIndex={props.imgIndex}
          onCloseModal={closeModalHandler}
          desc={props.desc}
        />
      )}
    </>
  );
};

export default GalleryImage;
/*

 */
