import Backdrop from "./Backdrop";
import ReactDOM from "react-dom";
import "./GalleryModal.css";
import ImageGallery from "react-image-gallery";
import { useRef } from "react";

const images = [
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_1_1.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-61.jpg",
    desc: "Парковка на 2 машины",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_1_2.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-63.jpg",
    desc: "Вид с дороги",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_1_3.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-64.jpg",
    desc: "Освещенная лестница в дом",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_1_4.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-59.jpg",
    desc: "Дорога внутри поселка",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_2_1.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-20.jpg",
    desc: "Входная дверь",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_2_2.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-21.jpg",
    desc: "Прихожая",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_2_3.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-22.jpg",
    desc: "Вход в гостиную",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_3_1.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-23.jpg",
    desc: "Лестница на второй этаж и гостиная",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_3_2.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-15.jpg",
    desc: "Гостиная",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_3_3.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-14.jpg",
    desc: "Удобный диван и большой телевизор",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_3_4.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-11.jpg",
    desc: "Вид на кухню-столовую",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_3_5.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-12.jpg",
    desc: "Эко-камин",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_3_6.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-13.jpg",
    desc: "Полки для книг и сувениров",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_3_7.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-32.jpg",
    desc: "Много воздуха за счет открытого пространства",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_4_1.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-27.jpg",
    desc: "Стол с розетками для подзарядки",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_4_2.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-16.jpg",
    desc: "Удобные винтажные стулья",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_4_3.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-33.jpg",
    desc: "Светлая кухня",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_4_4.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-18.jpg",
    desc: "Встроенная бытовая техника BOSCH",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_4_5.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-17.jpg",
    desc: "Профессиональная кофемашина, посудомоечная машина и измельчитель пищевых отходов",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_5_1.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-30.jpg",
    desc: "Терраса для утреннего каппучино",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_5_2.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-28.jpg",
    desc: "Вид на Национальный парк",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_5_3.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-29.jpg",
    desc: "В тени деревьев",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_5_4.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-31.jpg",
    desc: "Панорамные окна на 2 этажа",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_6_1.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-24.jpg",
    desc: "Тихая спальня на первом этаже",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_6_2.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-25.jpg",
    desc: "Кровать с отличным удобным матрасом",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_6_3.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-26.jpg",
    desc: "Комод для хранения вещей",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_7_1.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-53.jpg",
    desc: "Гостевой туалет и душевая зона",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_7_2.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-54.jpg",
    desc: "Раковина из натурального камня ручной работы",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_7_3.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-57.jpg",
    desc: "Встроенные смесители",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_7_4.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-56.jpg",
    desc: "Стеклянная перегородка душевой зоны",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_8_1.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-34.jpg",
    desc: "Винный шкаф, ледогенератор, лестница и выход на заднюю террасу",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_8_2.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-51.jpg",
    desc: "Гриль-зона и беседка на второй террасе",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_8_3.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-35.jpg",
    desc: "Удобная лестница на второй этаж",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_8_4.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-36.jpg",
    desc: "Светлая лестничная зона и вход в ванную комнату",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_9_1.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-65.jpg",
    desc: "Теневая задняя терраса",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_9_2.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-66.jpg",
    desc: "Газовый гриль NAPOLEON",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_10_1.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-41.jpg",
    desc: "Полки для книг и вход в спальню",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_10_2.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-43.jpg",
    desc: "Диван в кабинете и большой простор",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_10_3.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-37.jpg",
    desc: "Кабинет и вход в гардеробную",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_10_4.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-38.jpg",
    desc: "Оптоволоконный интернет 500 мБит",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_10_5.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-42.jpg",
    desc: "Зона отдыха",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_10_6.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-39.jpg",
    desc: "Вид на национальный парк из кабинета",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_10_7.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-40.jpg",
    desc: "Гардеробная",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_11_1.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-45.jpg",
    desc: "Второй свет, вид на первый этаж",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_11_2.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-44.jpg",
    desc: "Много света, очень много света",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_12_1.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-48.jpg",
    desc: "Ванная комната на втором этаже",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_12_2.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-49.jpg",
    desc: "Красивая ванна для отдыха",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_12_3.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-50.jpg",
    desc: "Самая бесшумная стиральная машина",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_13_1.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-46.jpg",
    desc: "Детская спальня на втором этаже",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/img/gallery_13_2.jpg",
    original: process.env.PUBLIC_URL + "/img/hires/Photo-47.jpg",
    desc: "Телевизор с мультиками",
  },
];

const ModalOverlay = (props) => {
  const galRef = useRef(null);

  const descRenderer = () => {
    let ind;
    if (galRef.current == null) {
      ind = props.imgIndex;
    } else {
      ind = galRef.current.getCurrentIndex();
    }
    const desc = images[ind].desc;
    return <div className="description">{desc}</div>;
  };

  return (
    <div className="modal">
      <div className="close-btn" onClick={props.onCloseModal}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <ImageGallery
        items={images}
        showFullscreenButton={false}
        showPlayButton={false}
        startIndex={props.imgIndex}
        onClick={props.onCloseModal}
        ref={galRef}
        renderCustomControls={descRenderer}
        additionalClass="gallery-element"
        lazyLoad={true}
      />
    </div>
  );
};

const GalleryModal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onCloseModal={props.onCloseModal} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          imgIndex={props.imgIndex}
          onCloseModal={props.onCloseModal}
          desc={props.desc}
        />,
        document.getElementById("overlay-root")
      )}
    </>
  );
};

export default GalleryModal;
