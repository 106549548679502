import "./Navigation.css";
import { useState } from "react";

const Navigation = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const menuClickHandler = () => {
    setIsMenuOpened((prevState) => {
      return !prevState;
    });
  };

  const hideMenuHandler = () => {
    setIsMenuOpened(false);
  };

  return (
    <nav>
      <button className="btn-mobile-menu" onClick={menuClickHandler}>
        <img src={process.env.PUBLIC_URL + "/img/btn-bars.svg"} alt="Menu" />
      </button>
      {isMenuOpened && <div className="backdrop" onClick={hideMenuHandler} />}

      <ul
        className={`menu-container ${!isMenuOpened && "hidden"}`}
        onClick={hideMenuHandler}
      >
        <li>
          <a href="#description" className="T1" onClick={hideMenuHandler}>
            Описание
          </a>
        </li>
        <li>
          <a href="#plan" className="T1" onClick={hideMenuHandler}>
            План дома
          </a>
        </li>
        <li>
          <a href="#gallery" className="T1" onClick={hideMenuHandler}>
            Фотогалерея
          </a>
        </li>
        <li>
          <a href="#video" className="T1" onClick={hideMenuHandler}>
            Видео
          </a>
        </li>
        <li>
          <a href="#location" className="T1" onClick={hideMenuHandler}>
            Местоположение
          </a>
        </li>
        <li>
          <a href="#sights" className="T1" onClick={hideMenuHandler}>
            Достопримечательности
          </a>
        </li>
        <li>
          <a href="#contacts" className="T1" onClick={hideMenuHandler}>
            Контакты
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
